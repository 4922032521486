<script setup lang="ts">
import type { UISliderBaseNavigationButtonProps } from './UISliderBaseNavigationButton.props'

const props = withDefaults(defineProps<UISliderBaseNavigationButtonProps>(), {
  disabled: false,
  customClass: '',
  ariaLabel: '',
})

// Compute button position classes based on direction
const buttonPositionClass = computed(() => ({
  'left-0': props.direction === 'prev',
  'right-0': props.direction === 'next',
}))

// Compute transform classes based on direction
const buttonTransformClass = computed(() => ({
  '-translate-x-[calc(var(--padding-x)_+_2px)]': props.direction === 'prev',
  'translate-x-full': props.direction === 'next',
}))
</script>

<template>
  <button
    type="button"
    :aria-label="
      ariaLabel || (direction === 'prev' ? 'Previous slide' : 'Next slide')
    "
    :disabled="disabled"
    class="navigation-button"
    :class="[
      customClass,
      buttonPositionClass,
      buttonTransformClass,
      disabled && 'cursor-not-allowed',
    ]"
  >
    <slot />
  </button>
</template>

<style lang="scss" scoped>
.navigation-button {
  @apply bg-neutral-white pointer-events-auto absolute top-0 z-10 flex h-full w-[var(--padding-x)] items-center justify-center;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  &:not(:disabled):hover {
    opacity: 1;
  }

  &:disabled {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  @screen md {
    display: flex;
  }
}
</style>
