<script setup lang="ts">
import { SLIDER_INJECTION_KEY } from './UISliderBase.props'
import type { UISliderInjectionState } from './UISliderBase.props'
import type { UISliderBaseScrollbarProps } from './UISliderBaseScrollbar.props'

const props = defineProps<UISliderBaseScrollbarProps>()

// Inject slider state
const sliderState = inject<UISliderInjectionState | null>(
  SLIDER_INJECTION_KEY,
  null
)

const scrollbarRef = ref<HTMLElement>()
const thumbRef = ref<HTMLElement>()
const isDragging = ref(false)
const startX = ref(0)
const scrollStartProgress = ref(0)

// Calculate thumb width based on measurements
const thumbWidth = computed(() => {
  if (!props.measurements) {
    console.log('No measurements available')
    return '20%'
  }

  const { viewportWidth, scrollWidth } = props.measurements

  // Calculate the ratio of viewport width to total scrollable width
  const ratio = Math.min((viewportWidth / scrollWidth) * 100, 100)
  console.log('Scrollbar width calculation:', {
    viewportWidth,
    scrollWidth,
    ratio,
  })
  return `${ratio}%`
})

// Calculate thumb position
const thumbPosition = computed(() => {
  if (!scrollbarRef.value || !thumbRef.value) return 0
  const scrollbarWidth = scrollbarRef.value.offsetWidth
  const thumbWidth = thumbRef.value.offsetWidth
  const maxTranslate = scrollbarWidth - thumbWidth
  return props.progress * maxTranslate
})

// Handle track click
const onTrackClick = (e: MouseEvent) => {
  if (
    !scrollbarRef.value ||
    !thumbRef.value ||
    isDragging.value ||
    !sliderState
  )
    return

  const scrollbarRect = scrollbarRef.value.getBoundingClientRect()
  const thumbWidth = thumbRef.value.offsetWidth
  const clickPosition = e.clientX - scrollbarRect.left
  const scrollbarWidth = scrollbarRect.width - thumbWidth

  const clickProgress = Math.max(0, Math.min(1, clickPosition / scrollbarWidth))
  const targetIndex = Math.round(clickProgress * (sliderState.slidesCount - 1))
  sliderState.scrollTo(targetIndex)
}

// Handle mouse down on thumb
const onThumbMouseDown = (e: MouseEvent) => {
  if (!sliderState) return

  e.stopPropagation() // Prevent track click when dragging thumb
  isDragging.value = true
  startX.value = e.clientX
  scrollStartProgress.value = props.progress

  // Add event listeners for drag
  window.addEventListener('mousemove', onMouseMove)
  window.addEventListener('mouseup', onMouseUp)

  // Prevent text selection while dragging
  document.body.style.userSelect = 'none'
}

// Handle mouse move during drag
const onMouseMove = (e: MouseEvent) => {
  if (
    !isDragging.value ||
    !scrollbarRef.value ||
    !thumbRef.value ||
    !sliderState
  )
    return

  const scrollbarRect = scrollbarRef.value.getBoundingClientRect()
  const thumbWidth = thumbRef.value.offsetWidth
  const scrollbarWidth = scrollbarRect.width - thumbWidth

  const deltaX = e.clientX - startX.value
  const deltaProgress = deltaX / scrollbarWidth

  let newProgress = scrollStartProgress.value + deltaProgress
  newProgress = Math.max(0, Math.min(1, newProgress))

  // Calculate the target slide index based on progress
  const targetIndex = Math.round(newProgress * (sliderState.slidesCount - 1))
  sliderState.scrollTo(targetIndex)
}

// Handle mouse up after drag
const onMouseUp = () => {
  isDragging.value = false
  window.removeEventListener('mousemove', onMouseMove)
  window.removeEventListener('mouseup', onMouseUp)
  document.body.style.userSelect = ''
}

// Clean up event listeners
onUnmounted(() => {
  window.removeEventListener('mousemove', onMouseMove)
  window.removeEventListener('mouseup', onMouseUp)
})
</script>

<template>
  <div
    v-if="sliderState"
    ref="scrollbarRef"
    class="scrollbar-container"
    @click="onTrackClick"
  >
    <div
      ref="thumbRef"
      class="scrollbar-thumb bg-neutral-gray"
      :style="{
        transform: `translateX(${thumbPosition}px)`,
        width: thumbWidth,
      }"
      @mousedown="onThumbMouseDown"
    />
  </div>
</template>

<style lang="scss" scoped>
.scrollbar-container {
  @apply relative h-1 w-full;
  border-radius: theme('borderRadius.full');
  overflow: hidden;
  cursor: pointer;
}

.scrollbar-thumb {
  @apply absolute h-full cursor-grab;
  border-radius: theme('borderRadius.full');
  will-change: transform;
  background: var(--Colors-Overlay-Black-10, rgba(0, 0, 0, 0.1));

  &:active {
    @apply cursor-grabbing;
  }
}
</style>
