<script setup lang="ts">
import { SLIDER_INJECTION_KEY } from './UISliderBase.props'
import type { UISliderInjectionState } from './UISliderBase.props'
import type { UISliderBaseBulletsProps } from './UISliderBaseBullets.props'

const props = withDefaults(defineProps<UISliderBaseBulletsProps>(), {
  containerClass: '',
  bulletClass: '',
  activeBulletClass: '',
})

// Inject slider state to access navigation methods
const sliderState = inject<UISliderInjectionState | null>(
  SLIDER_INJECTION_KEY,
  null
)

// Create array of slide indices
const slideIndices = computed(() =>
  Array.from({ length: props.slidesCount }, (_, i) => i)
)

// Handle bullet click
const handleBulletClick = (index: number) => {
  sliderState?.scrollTo(index)
}
</script>

<template>
  <div
    v-if="sliderState"
    class="bullets-container"
    :class="containerClass"
    role="tablist"
    aria-label="Slides navigation"
  >
    <button
      v-for="index in slideIndices"
      :key="index"
      type="button"
      role="tab"
      :aria-label="'Go to slide ' + (index + 1)"
      :aria-selected="index === selectedIndex"
      class="bullet"
      :class="[
        bulletClass,
        index === selectedIndex && ['bullet--active', activeBulletClass],
      ]"
      @click="handleBulletClick(index)"
    />
  </div>
</template>

<style lang="scss" scoped>
.bullets-container {
  @apply flex items-center justify-center gap-2 py-4;
}

.bullet {
  @apply bg-background-tertiary h-2 w-2 rounded-full transition-colors;

  &:hover {
    @apply bg-background-secondary;
  }

  &--active {
    @apply bg-text-primary;
  }
}
</style>
