<script setup lang="ts" generic="T">
import type { UISliderWithArrowsProps } from './UISliderWithArrows.props'
import type { EmblaOptionsType } from 'embla-carousel'

const props = withDefaults(defineProps<UISliderWithArrowsProps<T>>(), {
  loop: true,
  showScrollbar: false,
  showNavigation: true,
  showNavigationNextArrow: true,
  showNavigationPrevArrow: true,
  showBullets: false,
  hideLateralSlidesOnMobile: false,
})

const visibleNavigationPanel = ref<null | 'L' | 'R'>(null)

const containerRef = ref<HTMLElement>()
const emblaSlideRef = useTemplateRef('containerRef')

const { width: viewportWidth } = useWindowSize()

const getAlignmentValue = () => {
  if (!emblaSlideRef.value || !window) return 0
  const cardWidth = Math.round(emblaSlideRef.value.clientWidth)
  if (!cardWidth) return 0
  return (viewportWidth.value - cardWidth * 2) / 2
}

const options = computed<EmblaOptionsType>(() => ({
  loop: props.loop,
  startIndex: 0,
  skipSnaps: true,
  inViewThreshold: 1,
  align: props.hideLateralSlidesOnMobile ? 'start' : getAlignmentValue,

  breakpoints: {
    '(min-width: 1024px)': {
      containScroll: 'trimSnaps',
      slidesToScroll: 1,
      align: 'center',
    },
  },
}))

const { width } = useElementSize(containerRef)

const mousemoveHandler = (e: MouseEvent) => {
  if (!width.value) return
  const currentTarget = e.currentTarget as HTMLElement
  const x = e.pageX - (currentTarget.offsetLeft ?? 0)

  if (x < width.value / 2) {
    visibleNavigationPanel.value = 'L'
  } else {
    visibleNavigationPanel.value = 'R'
  }
}

const emblaContainerClass = computed(() => ({
  '!overflow-clip':
    props.showNavigation &&
    props.loop &&
    (!props.showNavigationNextArrow || !props.showNavigationPrevArrow),

  '-mx-[var(--padding-x)] !w-auto':
    props.showNavigation &&
    props.loop &&
    props.showNavigationNextArrow &&
    props.showNavigationPrevArrow &&
    !props.hideLateralSlidesOnMobile,

  'lg:-mx-[var(--padding-x)] lg:!w-auto':
    props.showNavigation &&
    props.loop &&
    props.showNavigationNextArrow &&
    props.showNavigationPrevArrow &&
    props.hideLateralSlidesOnMobile,

  'hide-lateral-slides-on-mobile': props.hideLateralSlidesOnMobile,
}))

const emblaSlidesClass = computed(() => [
  props.slideCustomWidth || 'w-6/12',
  props.loop && 'is-loop',
])

const itemsToRender = computed(() => {
  return props.loop ? sliderMinimumSlides(props.items, 10) : props.items
})
</script>

<template>
  <div
    v-if="itemsToRender.length"
    ref="containerRef"
    class="slider-container relative"
    :class="{
      'slider-on-right-edge': rightWindowEdge,
      'slider-with-scrollbar': showScrollbar,
    }"
    @mousemove="(e: any) => mousemoveHandler(e as MouseEvent)"
    @mouseleave="visibleNavigationPanel = null"
  >
    <UISliderBase :options="options" :class="emblaContainerClass">
      <div
        v-for="(item, index) in itemsToRender"
        :key="`item__${index}`"
        ref="emblaSlideRef"
        class="embla__slide shrink-0"
        :class="emblaSlidesClass"
      >
        <div class="h-full w-full">
          <slot v-bind="item" />
        </div>
      </div>

      <template #button-navigation-prev="{ disabled }">
        <UISliderBaseNavigationButton
          v-if="
            showNavigation &&
            showNavigationPrevArrow &&
            visibleNavigationPanel === 'L'
          "
          :disabled="disabled"
          direction="prev"
          class="!hidden lg:!flex"
          :class="[
            hasSlideVerticalBorder
              ? `!top-[2px] !h-[calc(100%_-_4px)]`
              : 'top-0 h-full',
          ]"
        >
          <DefaultIconsChevronLeft aria-hidden="true" />
        </UISliderBaseNavigationButton>
      </template>

      <template #button-navigation-next="{ disabled }">
        <UISliderBaseNavigationButton
          v-if="
            showNavigation &&
            showNavigationNextArrow &&
            visibleNavigationPanel === 'R'
          "
          :disabled="disabled"
          class="!hidden lg:!flex"
          direction="next"
          :class="[
            hasSlideVerticalBorder
              ? `!top-[2px] !h-[calc(100%_-_4px)]`
              : 'top-0 h-full',
          ]"
        >
          <DefaultIconsChevronRight aria-hidden="true" />
        </UISliderBaseNavigationButton>
      </template>

      <template #scrollbar="{ progress, measurements }">
        <UISliderBaseScrollbar
          v-if="showScrollbar"
          :progress="progress"
          :measurements="measurements"
        />
      </template>

      <template #bullets-navigation="{ selectedIndex, slidesCount }">
        <UISliderBaseBullets
          v-if="showBullets"
          :selected-index="selectedIndex"
          :slides-count="slidesCount"
        />
      </template>
    </UISliderBase>
    <!-- <div
      v-if="showScrollbar"
      ref="scrollBarContainer"
      class="scrollbar-container absolute bottom-0 left-0 right-0 hidden !w-[calc(100%_-_var(--padding-x))] overflow-clip lg:flex"
    >
      <div
        ref="scrollBar"
        class="scroll-bar-progress"
        :style="{
          width: `${scrollBarWidth}%`,
          transform: `translateX(${x}px)`,
        }"
      />
    </div> -->
  </div>
</template>

<style scoped lang="scss">
.slider-on-right-edge {
  @screen lg {
    margin-right: calc(var(--padding-x) * -1);
    :deep(.embla) {
      padding-right: var(--padding-x) !important;
    }
    :deep(.navigation-button-next) {
      transform: translateX(0);
    }
  }
}

:deep(.embla) {
  padding: 0 var(--padding-x);
  overflow: visible;

  &.hide-lateral-slides-on-mobile {
    @media (max-width: 1024px) {
      padding-inline: 0 !important;
    }
  }

  @screen lg {
    overflow: hidden;
  }
}

:deep(.navigation-button-prev),
:deep(.navigation-button-next) {
  transition: opacity 0.3s ease;
  visibility: visible;

  &[disabled] {
    visibility: hidden;
    opacity: 0;
  }
}

.slider-with-scrollbar {
  .embla {
    padding-bottom: 1.5rem !important;
  }
  .navigation-button-prev,
  .navigation-button-next {
    height: calc(100% - 1.5rem);
  }
}

.slider-container {
  overflow: visible;

  &:hover {
    .navigation-button {
      opacity: 1;
    }
  }
}

// .embla__slide {
//   &.is-loop {
//     &:first-child {
//       z-index: 1000000;
//     }
//   }
// }

:deep(.scrollbar-container) {
  @apply absolute bottom-0 left-0 right-0 hidden !w-[calc(100%_-_var(--padding-x))] translate-y-3 overflow-clip lg:flex;
}

:deep(.scroll-bar-progress) {
  height: 4px;
  border-radius: 3px;
  background: var(--Colors-Overlay-Black-10, rgba(0, 0, 0, 0.1));
  cursor: grab;
}
</style>
